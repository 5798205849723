import React from "react";
import styles from "./Footer.module.css";
import Logo from "../../vp_2.svg";

function Footer() {

    return (
        <div className={styles.footer}>
            <p>©Volle Petrol - 2025</p>
        </div>
    );
}


export default Footer;
