import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom"; // Import Link from React Router
import styles from "./Header.module.css";
import Logo from "../../vp_2.svg";
import NavLogo from "../../images/nav_white.png";

function Header() {
    const location = useLocation();
    const [navbarActive, setNavbarActive] = useState(false); // Corrected state usage

    function handleNavbar() {
        setNavbarActive((prev) => !prev);
        console.log(navbarActive);
    }

    return (
        <div className={styles.header}>
            <Link to="/home">
                <img className={styles.logo} src={Logo} alt="Logo"/>
            </Link>

            <div className={styles.navItems}>
                <div className={styles.showNavbar} onClick={handleNavbar}>
                    <img className={styles.navImage} src={NavLogo} alt="Navigation Toggle"/>
                </div>

                <ul className={`${styles.navbar} ${navbarActive ? styles.active : ""}`}>
                    <li className={`${styles.navItem} ${location.pathname === "/home" ? styles.active : ""}`}>
                        <Link onClick={handleNavbar} to="/home">Home</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}


export default Header;
